
import { motion } from 'framer-motion';
import { bannerAnimation } from '../constants/animations';

import { Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { BaskervvilleTypography } from 'components/CustomTypography';

export default function TitleBanner(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const marginTop = (isMobile) ? 10 : 6;
  const marginBottom = (isMobile) ? 2 : 0;

  return (
    <motion.div 
      initial="initial"
      animate="in"
      exit="out"
      variants={bannerAnimation}
    >
      <Box mt={marginTop} mb={marginBottom}>
        <BaskervvilleTypography variant="h2"> { props.text } </BaskervvilleTypography>
      </Box>
    </motion.div>
  );
}

