
import './SkillSection.css';
import { Typography } from '@material-ui/core';
// import TitleBanner from 'components/TitleBanner';

import { MY_SKILLS } from 'constants/info';

export default function SkillSection() {

  return (
    <div id="skills" class="features">
      {
        // <TitleBanner text="Skills" />
      }

      { 
        Object.keys(MY_SKILLS).map((aSkillCategory) => {
          return (
            <div class="skill-category">

              <Typography variant="h5" color="primary"> { aSkillCategory } </Typography>
              <ul>
                { 
                  MY_SKILLS[aSkillCategory].map((aSkill) => <li> 
                    <Typography>
                      { aSkill } 
                    </Typography>
                  </li>)
                }
              </ul>
            </div>
          )
        })
      }

    </div>

  );

}

