
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const handleeFont = "'Handlee', cursive";
const openSansFont = "'Open Sans', sans-serif";
const baskervvilleFont = "'Baskervville', serif";


export const colorscheme = {
  palegold: "#C0A484", 
  // background: "#262728", 
  // background: "#000", 
  background: "#0a192f", 
  // background: "#121212",
  grey: "#707172", 
  white: "lightgrey",
  black: "#121212",
  pink: "#FEECDF",

  cyan: "#00ffff",
  maple: "#ac7147",
  brown: "#6C4B34",
  babyblue: "#ACDADD",
  // cream: "#f0d4af",
  cream: "#e6f1ff",

  coffee: "#C5A680",
  gold: "#AC8759",
  brick: "#4a2c2a",
  mahogany: "#BF675A",
  green: "#64ffda", 
  lilac: "#8892b0",
}
// $grey: #4b4339;

const customizedTheme = createMuiTheme({
  palette: {
    primary: {
      main: colorscheme.green
    }, 
    secondary: {
      main: colorscheme.lilac
    }, 
    background: {
      default: colorscheme.background
    },
    error: {
      main: colorscheme.cyan,
      default: colorscheme.cyan
      // main: colorscheme.black
    }, 
    text: {
      secondary: colorscheme.white
    },
    dark: colorscheme.black, 
    cream: {
      main: colorscheme.cream
    }, 
    // cyan: colorscheme.cyan,
  }, 
  typography: {
    // fontFamily: handleeFont,
    // fontFamily: baskervvilleFont,
    fontFamily: openSansFont,
    allVariants: {
      color: colorscheme.cream
      // color: colorscheme.maple
    },
    body1: {
      fontSize: "1.1em"
    },
  }
});

export const theme = responsiveFontSizes(customizedTheme);
// export const theme = customizedTheme;

// h5: {
//   [breakpoints.down("xs")]: {
//     fontSize: "1em"
//   }
// }

