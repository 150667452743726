
export const fadeAnimation = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1.2,
    transition: {
      duration: 1,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
  out: {
    opacity: 0,
  },
}

export const bannerAnimation = {
  initial: {
    y: -200,
    opacity: 0
  },
  in: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
  out: {
    opacity: 0
  }
}

export const firstColAnimation = {
  initial: {
    y: 200,
    opacity: 0
  },
  in: {
    animationDelay: 0,
    y: 0,
    opacity: 1,
    transition: {
      duration: 1,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
  out: {
    opacity: 0
  }
}

export const secondColAnimation = {
  initial: {
    y: 200,
    opacity: 0
  },
  in: {
    animationDelay: 1,
    y: 0,
    opacity: 1,
    transition: {
      duration: 2,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
  out: {
    opacity: 0
  }
}

