
import { withStyles, Typography } from '@material-ui/core/';

export const HandleeTypography = withStyles({
  root: {
    fontFamily: "'Handlee', cursive",
  }
})(Typography);

export const BaskervvilleTypography = withStyles({
  root: {
    fontFamily: "'Baskervville', serif"
  }
})(Typography);

