
import { Box, Typography } from '@material-ui/core/';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { HandleeTypography } from './CustomTypography';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.dark
  },
}));

export default function AppFooter() {

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box 
      height={(isMobile) ? "20vh" : "16vh"}
      className={classes.root}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >

      <div style={{width: "90%"}}>
        <Typography variant="h5" style={{display: 'inline-block'}}>
          <b> KEVIN HSU </b>
        </Typography>

        <Typography variant="h5" color="primary" style={{display: 'inline-block'}}>
          <b> &nbsp;&copy;2021 </b>
        </Typography>

        <br/>
        <Typography variant={(isMobile) ? "h6" : "h5"} color="textSecondary">
          <i> Created with love, care, and many cups of coffee </i>
        </Typography>
      </div>

    </Box>
  );

}

