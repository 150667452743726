import './App.css';

import { Box } from '@material-ui/core';

import AppHeader from './components/AppHeader';
import AppFooter from './components/AppFooter';
import LandingSection from './views/LandingSection';
import ProjectSection from './views/ProjectSection';
import SkillExperience from './views/SkillExperience';
import AboutPage from './views/AboutPage';

// const routes = [
//   { path: '/', name: 'Home', Component: LandingSection },
//   { path: '/projects', name: 'Projects', Component: ProjectSection },
//   { path: '/experiences', name: 'Skills & Experiences', Component: SkillExperience },
//   { path: '/about', name: 'About Me', Component: AboutPage }
// ];

function App() {
  return (
    <div className="App">
      <Box height="8vh">
        <AppHeader />
      </Box>

      <div id={'landing-section'}>
        <LandingSection />
      </div>

      <div id={'skill-experience'}>
        <SkillExperience/>
      </div>

      <div id={'project-section'}>
        <ProjectSection/>
      </div>

      <div id={'about-page'}>
        <AboutPage/>
      </div>
      <AppFooter />
    </div>
  );
}

export default App;
