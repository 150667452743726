import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { Card, CardActionArea, CardActions, CardContent, CardMedia, Chip, 
Button, Typography, Box } from '@material-ui/core'

import Collapse from '@material-ui/core/Collapse';
// import IconButton from '@material-ui/core/IconButton';
// import { red } from '@material-ui/core/colors';
// import Avatar from '@material-ui/core/Avatar';
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import ShareIcon from '@material-ui/icons/Share';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    margin: "20px 10px",
    borderRadius: 0,
    backgroundColor: theme.palette.dark
  }, 
  chipContainer: {
    width: "90%",
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  }
}));

export default function GalleryCard({ onLoad, title, description, thumbnail, url, tags, currentlyExpanded, setCurrentlyExpanded }) {
  const classes = useStyles();
  const img = require.context('static/projects', true);
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
    setCurrentlyExpanded(title);
  };

  useEffect(() => {
    if (currentlyExpanded != title) {
      setExpanded(false);
    }
  }, [currentlyExpanded])

  useEffect(() => {
    onLoad();
  }, [])

  function renderCollapsed() {
    return (
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            { title }
          </Typography>

          <Typography variant="body2" color="textSecondary" style={{whiteSpace: 'pre-line'}}>
            { description }
          </Typography>

            <Box m="0 auto" my={2} className={classes.chipContainer}>
              { tags.map((aTag) => <Chip label={aTag} variant="outlined" color="primary" size="small" />) }
            </Box>

          <a target="_blank" href={url}>
            <Button size="small" color="primary" variant="outlined" style={{ width: "50%", color: "#00ffff", borderColor: "#00ffff" }}>
              Link
            </Button>
          </a>

          {
          // <Button size="small" color="primary">
          //   Learn More
          // </Button>
          }
        </CardContent>
      </Collapse>
    );
  }

  return (
    <Card className={classes.root}>
      <CardActionArea onClick={handleExpandClick}>
        <CardMedia
          component="img"
          alt="project"
          image={ thumbnail }
          // title="Contemplative Reptile"
        />
      </CardActionArea>
      { renderCollapsed() }
    </Card>
  );
}
