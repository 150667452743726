import React, { useEffect } from 'react';
// import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { Card, CardActionArea, CardContent, CardMedia, Chip, 
Typography, Box } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    minHeight: 400,
    margin: "20px 10px",
    borderRadius: 0,
    backgroundColor: theme.palette.dark, 
  }, 
  chipContainer: {
    width: "90%",
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.3),
    },
    // minHeight: 55
  }, 
  descriptionContainer: {
    whiteSpace: 'pre-line', 
    // minHeight: 130
  }
}));

export default function SquareCard({ onLoad, title, description, thumbnail, url, tags, currentlyExpanded, setCurrentlyExpanded }) {
  const classes = useStyles();
  const img = require.context('static/projects', true);

  useEffect(() => {
    onLoad();
  }, [])

  function renderCollapsed() {
    return (
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            { title }
          </Typography>

          <Box display="flex" flexDirection="column" minHeight="190px" justifyContent="space-between">
            <Typography variant="body2" color="textSecondary" className={classes.descriptionContainer}>
              { description }
            </Typography>

            <Box m="0 auto" my={2} className={classes.chipContainer}>
              { tags.map((aTag) => <Chip label={aTag} variant="outlined" color="primary" size="small" />) }
            </Box>
          </Box>

        </CardContent>
    );
  }

  return (
      <Card className={classes.root}>
        <CardActionArea target="_blank" href={url}>
          <CardMedia
            component="img"
            height="200"
            alt="project"
            image={ thumbnail }
          />
          { renderCollapsed() }
        </CardActionArea>
      </Card>
  );
}
