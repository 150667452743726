
import { Box, IconButton } from '@material-ui/core';

import { GitHub, LinkedIn, Email, GetApp } from '@material-ui/icons';

export default function ContactInfo(props) {

  return (
    <Box>
      <a href="https://github.com/kaisucode">
        <IconButton aria-label="github" color={props.color}>
          <GitHub fontSize={props.size} />
        </IconButton>
      </a>
      <a href="https://www.linkedin.com/in/hsu-kevin/">
        <IconButton aria-label="linkedin" color={props.color}>
          <LinkedIn fontSize={props.size} />
        </IconButton>
      </a>
      <a href="mailto:kevin.hsukaihao@gmail.com">
        <IconButton aria-label="email" color={props.color}>
          <Email fontSize={props.size} />
        </IconButton>
      </a>

      {
        // <Button
        //   variant="contained"
        //   color="primary"
        //   startIcon={<GetApp />}
        //   style={{color: colorscheme.background}}
        // >
        //   <b> My Resume </b>
        // </Button>
      // <IconButton aria-label="resume" color={props.color}>
      //   <GetApp fontSize={props.size} />
      //   <Typography variant="body2">
      //     My Resume
      //   </Typography>
      // </IconButton>
      }
    </Box>
  );
}


