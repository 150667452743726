
import { makeStyles, Paper, Typography, Box} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { CheckBoxOutlineBlank, Work } from '@material-ui/icons';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, 
  TimelineContent, TimelineOppositeContent, TimelineDot  } from '@material-ui/lab';

// import TitleBanner from 'components/TitleBanner';
import { MY_EXPERIENCES } from 'constants/info';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
    // backgroundColor: theme.palette.dark
    backgroundColor: "#262728"
  },
  secondaryTail: {
    backgroundColor: "red"
  },
  cyan: {
    color: theme.palette.error.main
  },
  cream: {
    color: theme.palette.cream.main
  }
}));

const TimelinePiece = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <TimelineItem>
      <TimelineOppositeContent>
        <Typography variant="body2" color="textSecondary">
          { props.date }
        </Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot color="primary" variant="outlined" className={classes.cyan}>
          {
            // <Work />
          }
          <CheckBoxOutlineBlank />
        </TimelineDot>
        { !props.last && <TimelineConnector /> }
      </TimelineSeparator>
      <TimelineContent>
        <Box width="100%" display="flex" justifyContent={ props.isEven ? "flex-end" : "flex-start" }>
          <Box width={(isMobile) ? "100%" : "60%"}>
            <Paper elevation={3} className={ classes.paper }>
              <center>
                <Typography variant={(isMobile) ? "caption" : "h6"} component="h1" color="primary"> { props.content } </Typography>
                <Typography variant={(isMobile) ? "caption" : "primary"} className={classes.cream} display="block">{ props.title }</Typography>
                { props.title1 &&
                  <Typography variant={(isMobile) ? "caption" : "primary"} className={classes.cream} display="block">{ props.title1 }</Typography>
                }
                { props.title2 &&
                  <Typography variant={(isMobile) ? "caption" : "primary"} className={classes.cream} display="block">{ props.title2 }</Typography>
                }
              </center>
            </Paper>
          </Box>
        </Box>
      </TimelineContent>
    </TimelineItem>
  );
};

export default function ExperienceSection() {

  return (
    <>
      {
        // <TitleBanner text="Experiences"/>
      }
      <br />
      <Timeline align="alternate">
        {
          MY_EXPERIENCES.map((anExperience, index) => 
            <TimelinePiece 
              isEven={index%2}
              date={anExperience.date}
              last={anExperience.last} 
              title={anExperience.title} 
              title1={anExperience.title1}
              title2={anExperience.title2}
              content={anExperience.content}
            />
          )
        }
      </Timeline>
    </>
  );
}

