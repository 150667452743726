
import React, { useState } from 'react';

import { Box, Card, CardMedia } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

import TitleBanner from 'components/TitleBanner';
import { MY_ART } from 'constants/info';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    margin: "20px 10px",
    borderRadius: 0,
    backgroundColor: theme.palette.dark
  }
}));

export default function ArtGallery(props) {

  const classes = useStyles();

  const [loaded, setLoaded] = useState(false);

  function renderGalleryCard(aProject) {
    return (
      <>
        {(loaded) 
          ? null 
          : <Box mx={1} my={1} >
              <Skeleton 
                variant="rect" 
                width={300} 
                height={300} 
                animation="wave"/>
            </Box>
        }

        <Card style={loaded ? {} : {display: 'none'}} className={classes.root}>
          <CardMedia
            component="img"
            alt="piece"
            onLoad={() => setLoaded(true)
                // setTimeout(() => , 1000)
            }
            image={ aProject.thumbnail }
          />
        </Card>
      </>
    )
  }

  function renderGalleryColumn(columnId) {
    return MY_ART.map((aProject) => {
      if (aProject.col === columnId) {
        return renderGalleryCard(aProject)
      }
    }
    );
  }

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end">
        <TitleBanner text="Art Gallery"/>
      </Box>
      <Box display="flex" justifyContent="center">
        { MY_ART &&
        <>
          <Box> { renderGalleryColumn(0) } </Box>
          <Box> { renderGalleryColumn(1) } </Box>
          <Box> { renderGalleryColumn(2) } </Box>
        </>
        }
      </Box>
      {
        // { MY_PROJECTS.map((aProject) => renderGalleryCard(aProject))}
      }
    </Box>
  );
}


