
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Box, Divider, Typography } from '@material-ui/core';

// import TitleBanner from 'components/TitleBanner';
import ContactInfo from 'components/ContactInfo';
import ArtGallery from 'views/ArtGallery';

export default function AboutPage() {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const aboutContents = [
    {
      title: "kevin hsu", 
      content: "computer science & visual art"
    },
    {
      title: "about me", 
      content: `I can (almost?) land a kickflip, drink a little too much coffee, and have a deep obsession for YA novels. Also, I was a Diamond rank Starcraft II player. Just sayin' \n
            I like to build things that either make me happy or make my life easier (sometimes both), and writing code gives me an outlet for that.`
    }
  ];

  function renderAboutSection({ title, content }) {
    return (
      <>
        <Typography variant="body1" color="secondary">
          { title }
        </Typography>
        <Box ml="20px">
          <Typography variant="body1" style={{whiteSpace: 'pre-line'}}>
            { content }
          </Typography>
        </Box>

        <Divider style={{ height: "1px", backgroundColor: "lightgrey", margin: "2rem 0" }}/>
      </>
    );
  }

  function renderContactInfo() {
    return (
      <>
        <Typography variant="body1" color="secondary">
          contact
        </Typography>
        <Box ml="20px">
          <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
            <ContactInfo color="primary"/>
          </Typography>
        </Box>
      </>
    );
  }

  return (
    <>
      <Box display="flex" flexDirection={(isMobile) ? "column" : "row"} alignItems={(isMobile) ? "center" : "flex-start"} justifyContent="center" style={{ textAlign: "left", margin: "5%" }}>

        <Box width={(isMobile) ? "90%" : "30%"} mt={15}>
          { aboutContents.map((aTopic) => renderAboutSection(aTopic)) }
          { renderContactInfo() }
        </Box>

        <Box width="6%" />

        <ArtGallery/>
      </Box>
    </>
  );
}

