
import { Divider } from '@material-ui/core';
import TitleBanner from 'components/TitleBanner';
import SkillSection from './SkillSection';
import ExperienceSection from './ExperienceSection';

export default function SkillExperience() {

  return (
    <>
      <TitleBanner text="Skills & Experiences"/>
      <ExperienceSection/>
      <Divider style={{ height: "1px", backgroundColor: "lightgrey", margin: "2rem auto", width: "75%" }}/>
      <SkillSection/>
    </>
  );
}

