
import React from 'react';
import { AppBar, Toolbar, Typography, CssBaseline, useScrollTrigger, withStyles, 
  Box, Container, Slide, Button } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
// import { HandleeTypography } from './CustomTypography';

import { Link, animateScroll as scroll } from "react-scroll";

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import clsx from 'clsx';
import { IconButton, Drawer, List, ListItem, ListItemText } from '@material-ui/core/';
import MenuIcon from '@material-ui/icons/Menu';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';


const ScrollLink = (props) => {

  return (
      <Link
        activeClass="active"
        to={props.to}
        spy={true}
        smooth={true}
        offset={-100}
        duration={500}
      >
        {props.children}
      </Link>
  );

};

const OptionLink = (props) => {

  const OptionButton = withStyles((theme) => ({
    root: {
      textTransform: 'none',
      display: 'inline-block', 
      padding: "0", 
      minHeight: "0", 
      minWidth: "0"
    },
  }))(Button);

  return (
    <Box mx={2} style={props.style}>
      <ScrollLink to={props.to}>
        <OptionButton> 
          <Typography 
            variant="h6" 
            color={(props.shouldUnderline) ? "primary" : "secondary"}
            style={(props.shouldUnderline) ? {textDecoration: "underline"} : {}}>
            { props.title }
          </Typography>
        </OptionButton>
      </ScrollLink>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({ 
  root: {
    backgroundColor: theme.palette.dark
  },
  MuiDrawer: {
    backgroundColor: theme.palette.dark
  }, 
  menuButton: {
    // backgroundColor: theme.palette.cream.main
    color: theme.palette.cream.main
  },
}));

// const routes = [
//   { title: "home", path: "/" }, 
//   { title: "projects", path: "/projects" }, 
//   { title: "skills & experiences", path: "/experiences" }, 
//   { title: "about me!!", path: "/about" }
// ];
const routes = [
  { title: "home", path: "landing-section" }, 
  { title: "skills & experiences", path: "skill-experience" }, 
  { title: "projects", path: "project-section" }, 
  { title: "about me!!", path: "about-page" }
];

export default function AppHeader(props) {

  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (open) => (event) => {
    let anchor = "left"
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>

        { routes.map((aRoute, index) => 
        <ScrollLink to={aRoute.path}>
          <ListItem key={aRoute.title}>
            <Typography 
              variant="h6" 
              color="primary" 
            >
              { aRoute.title }
            </Typography>
          </ListItem>
        </ScrollLink>
        )
        }

      </List>
    </div>
  );

  // <AppBar style={{ backgroundColor: "transparent" }}>
  // <AppBar style={{ backgroundColor: colorscheme.black }}>
  return (
    <React.Fragment>
      <CssBaseline />
        <AppBar className={classes.root}>
          <Toolbar>

            <Box display="flex" flexDirection={(isMobile) ? "row-reverse": "row"} justifyContent="space-between" alignItems={(isMobile) ? "center" : "flex-end"} my={3} mx={(isMobile) ? 6 : 20} width="100%">
              <Box mr={8}>
                <div style={{ cursor: "pointer" }}>
                <ScrollLink to={routes[0].path}>
                  <Typography color="primary" variant="h4">kevin hsu</Typography>
                </ScrollLink>
                </div>
              </Box>

              {(isMobile)
                ? <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer(true)}
                    edge="start"
                    className={clsx(classes.menuButton, state["left"] && classes.hide)}
                  >
                    <MenuIcon color={props.color}/>
                  </IconButton>

                : <Box display="flex" flexDirection="row">
                  { routes.map((aRoute) => 
                  <OptionLink 
                    title={aRoute.title}
                    to={aRoute.path}
                  />)
                  }
                </Box>

              }
            </Box>

          </Toolbar>
        </AppBar>

      <Drawer 
        anchor="left" 
        open={state["left"]} 
        onClose={toggleDrawer(false)}
        classes={{ paper: classes.MuiDrawer }}
      >
        {list("left")}
      </Drawer>
      {
        // <Toolbar />
      }
    </React.Fragment>
  );
}

