
import React, { useState } from 'react';
import { Box, Typography, Tab, Tabs } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Skeleton from '@material-ui/lab/Skeleton';

import { motion } from 'framer-motion';
import { colOneAnimation, secondColAnimation } from '../constants/animations';

import GalleryCard from 'components/GalleryCard';
import SquareCard from 'components/SquareCard';
import TitleBanner from 'components/TitleBanner';
import { MY_PROJECTS, MY_CATEGORIES, MY_CATEGORIES_MOBILE } from 'constants/info';

export default function ProjectsPage() {

  const theme = useTheme();
  const [loaded, setLoaded] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [currentlyExpanded, setCurrentlyExpanded] = useState("");
  const [filterKey, setFilterKey] = useState(0);

  function handleChange(e, newFilterKey) {
    setFilterKey(newFilterKey);
  }

  function renderGalleryCard(aProject, CardType) {
    return (
      <>
        {(loaded) 
          ? null 
          : <Box mx={1} my={1} >
              <Skeleton 
                variant="rect" 
                width={300} 
                height={300} 
                animation="wave"/>
            </Box>
        }

        <div style={loaded ? {} : {display: 'none'}}>

          {  
            CardType 
              ?  
              <CardType 
                onLoad={() => setLoaded(true)}
                title={aProject.title}
                description={aProject.description}
                url={aProject.url}
                thumbnail={aProject.thumbnail}
                tags={aProject.tags}
                currentlyExpanded={currentlyExpanded}
                setCurrentlyExpanded={setCurrentlyExpanded}
              />
              :
              <GalleryCard 
                onLoad={() => setLoaded(true)}
                title={aProject.title}
                description={aProject.description}
                url={aProject.url}
                thumbnail={aProject.thumbnail}
                tags={aProject.tags}
                currentlyExpanded={currentlyExpanded}
                setCurrentlyExpanded={setCurrentlyExpanded}
              />

          }
        </div>
      </>
    );
  }

  function renderGalleryAll() {

    const selectedCategory = MY_CATEGORIES[filterKey];
    if (selectedCategory == "all") { // all
      return (
        <>
          <Box> { renderGalleryColumn(0) } </Box>
          <Box> { renderGalleryColumn(1) } </Box>
          <Box> { renderGalleryColumn(2) } </Box>
        </>
      );

    }

    let columns = [[], [], []];
    let count = 0;

    MY_PROJECTS.map((aProject, idx) => {
      if (aProject.categories.includes(selectedCategory)) {
        columns[count % 3].push(idx);
        count++;
      }
    });

    return columns.map((aColumn) => 
      <Box>
        { aColumn.map((aProjectIdx) => {
            return renderGalleryCard(MY_PROJECTS[aProjectIdx], SquareCard)
          })}
      </Box>
    );
  
    // return indexes.map((anIndex) => {
    //   return renderGalleryCard(MY_PROJECTS[anIndex]);
    // });

    // return MY_PROJECTS.map((aProject) => {
    //     if (aProject.col === columnId) {
    //       return renderGalleryCard(aProject)
    //     }
    //   }
    // );


    // return MY_PROJECTS.map((aProject) => {
    //   const selectedCategory = MY_CATEGORIES[filterKey];
    //   if (aProject.categories.includes(selectedCategory)) {
    //     return renderGalleryCard(aProject);
    //   }
    // })
  }

  function renderGalleryAllMobile() {

    const selectedCategory = MY_CATEGORIES_MOBILE[filterKey];
    if (selectedCategory == "all") { // all
      return (
        <>
          <Box> 
            { renderGalleryColumn(0) } 
            { renderGalleryColumn(1) } 
            { renderGalleryColumn(2) } 
          </Box>
        </>
      );

    }

    let columns = [[], [], []];
    let count = 0;

    MY_PROJECTS.map((aProject, idx) => {
      if (aProject.categories.includes(selectedCategory)) {
        columns[count % 3].push(idx);
        count++;
      }
    });

    return columns.map((aColumn) => 
      <>
        { aColumn.map((aProjectIdx) => {
            return renderGalleryCard(MY_PROJECTS[aProjectIdx], SquareCard)
          })}
      </>
    );
  }

  function renderGalleryColumn(columnId) {
    return MY_PROJECTS.map((aProject) => {
        if (aProject.col === columnId) {
          return renderGalleryCard(aProject)
        }
      }
    );
  }

  return (
    <>
      <TitleBanner text="Projects"/>

      { isMobile
        ? (<Box display="flex" justifyContent="center" mt={2}>
          <Tabs
            value={filterKey}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            <Tab label="Featured" />
            <Tab label="Visual Computing" />
            <Tab label="Systems" />
            <Tab label="Fullstack" />
            <Tab label="GameDev" />
            <Tab label="All" />
          </Tabs></Box>)

          : (<Box display="flex" justifyContent="center" mt={2}>
            <Tabs value={filterKey} onChange={handleChange}>
              <Tab label="All" />
              <Tab label="Featured" />
              <Tab label="Visual Computing" />
              <Tab label="Systems" />
              <Tab label="Fullstack" />
              <Tab label="GameDev" />
            </Tabs>
          </Box>)
      }

      <Box display="flex" justifyContent="center" mb={8}>
        { MY_PROJECTS && isMobile
              ? (<Box>
                { renderGalleryAllMobile() }
              </Box>)
              : (<>
                { renderGalleryAll() }
              </>)
        }
      </Box>
      {
      // { MY_PROJECTS.map((aProject) => renderGalleryCard(aProject))}
      }

    </>
  );
}

